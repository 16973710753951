import { customDateFormatWithTime, statusFormatterWithMap, textFormatter } from "components/Common/formatter";
import { STATUS_COLOR_MAP } from "./constants";
import CustomLink from "../../../components/Common/buttons/CustomLink";
import NGITooltip from "../../../components/Common/utils/NGITooltip";
import React from "react";
import { userHasOneOfRoles } from "../../../utils/helpers/functions";
import { USER_ROLES } from "../../../utils/constants";

export const shipmentListColumns = () => [
  {
    title: "Shipment ID",
    dataIndex: "id",
    render: id =>
      userHasOneOfRoles([
        USER_ROLES.SYSTEM_FULL_ADMINS,
        USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
        USER_ROLES.METER_ENGINEER_LEADERSHIP,
        USER_ROLES.IT_OPERATIONS,
        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        USER_ROLES.WAREHOUSE,
        USER_ROLES.LOGISTICS,
      ]) ? (
        <NGITooltip title="Open details">
          <CustomLink to={`/multi-tenant/shipment/${id}`}>{id}</CustomLink>
        </NGITooltip>
      ) : (
        id
      ),
  },
  {
    title: "To Tenant",
    dataIndex: "toTenant",
    render: textFormatter,
  },
  {
    title: "To Station",
    dataIndex: "toDepot",
    render: textFormatter,
  },
  {
    title: "Total Qty",
    dataIndex: "totalAssetsQuantity",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: value => statusFormatterWithMap(value, STATUS_COLOR_MAP),
  },
  {
    title: "Carrier",
    dataIndex: "carrierName",
    render: textFormatter,
  },
  {
    title: "Tracking Number",
    dataIndex: "trackingNumber",
    render: textFormatter,
  },
  {
    title: "Date Created",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
];
