import { customDateFormatWithTime, statusFormatterWithMap, textFormatter } from "components/Common/formatter";
import ButtonGroupContainer from "../../../components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "../../../components/Common/buttons/ButtonGroup";
import { USER_ROLES } from "../../../utils/constants";
import deleteIcon from "../../../assets/images/svg/Delete.svg";
import React from "react";
import { Tooltip } from "antd";
import QRCode from "react-qr-code";
import { STATUS_COLOR_MAP } from "./constants";

export const boxListColumns = onDelete => [
  {
    title: "Box ID",
    dataIndex: "id",
    render: value => (
      <Tooltip placement="bottom" title={<QRCode size={180} value={value} />} color="#fff">
        <div>{value}</div>
      </Tooltip>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: value => statusFormatterWithMap(value, STATUS_COLOR_MAP),
  },
  {
    title: "Tenant",
    dataIndex: "tenant",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Asset Type",
    dataIndex: "assetGroup",
    render: textFormatter,
  },
  {
    title: "State",
    dataIndex: "assetState",
    render: textFormatter,
  },
  {
    title: "Condition",
    dataIndex: "assetCondition",
    render: textFormatter,
  },
  {
    title: "Qty",
    dataIndex: "assetQuantity",
    render: textFormatter,
  },
  {
    title: "Pallet ID",
    dataIndex: "parentContainerId",
    render: textFormatter,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
  {
    title: "",
    key: "action",
    render: (text, record) => (
      <ButtonGroupContainer>
        <ButtonGroup
          roles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD, USER_ROLES.WAREHOUSE]}
          tooltipTitle="Delete"
          icon={deleteIcon}
          handleClick={() => onDelete(record.id)}
        />
      </ButtonGroupContainer>
    ),
  },
];
