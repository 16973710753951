import { notification } from "antd";
import { getFormattedResult } from "pages/meter-management/configuration/meters/utils";
import { history } from "store";

const additionalConfigs = {
  placement: "top",
  className: "ngi-notification",
};

const DURATION_SEC = 2.5;

export const successMessage = (message, duration = DURATION_SEC, btn) => {
  notification.success({
    message,
    duration,
    btn,
    ...additionalConfigs,
  });
};

export const errorMessage = (message, duration = DURATION_SEC) =>
  notification.error({
    message,
    duration,
    ...additionalConfigs,
  });

export const warnMessage = (message, duration = DURATION_SEC) =>
  notification.warn({
    message,
    duration,
    ...additionalConfigs,
  });

export const defaultNgiErrorMethod = (error, duration = DURATION_SEC) => {
  let errMessage;
  if (
    !!error.response?.data?.errors?.length &&
    !!error.response?.data?.errors[0]?.code &&
    error.response?.data?.errors[0]?.code === "ACCESS_DENIED"
  ) {
    history.push("/no-access");
    return;
  }

  if (error.response?.data?.errors) {
    if (typeof error.response?.data?.errors === "string") errMessage = error.response?.data?.errors;
    else if (!!error.response?.data?.result)
      errMessage = `${error.response?.data?.errors[0]["desc"]}, ${error.response?.data?.errors[0]["items"]?.join(
        ", "
      )}. ${getFormattedResult(error.response.data.result)}`;
    else if (error.response?.data?.errors.length > 1) {
      const messages = error.response?.data?.errors.map(item => {
        if (!!error.response?.data?.errors[0]?.items) {
          if (typeof item?.items[0] === "object") {
            return `${item["desc"]}, ${item?.items.map(item => Object.values(item)).join(", ")}`;
          }
          return `${item["desc"]}, ${item?.items.join(", ")}`;
        }
        return item["desc"];
      });
      return messages.forEach(msg => errorMessage(msg, duration));
    } else if (!!error.response?.data?.errors[0]?.items) {
      const items = error.response.data.errors[0].items.map(item => {
        if (!!item?.partName) return `${item.partName} - ${item?.count}`;
        return item;
      });
      if (typeof items[0] === "object") {
        errMessage = `${error.response?.data?.errors[0]["desc"]}, ${items.map(item => Object.values(item)).join(", ")}`;
      } else {
        errMessage = `${error.response?.data?.errors[0]["desc"]}, ${items.join(", ")}`;
      }
    } else errMessage = error.response?.data?.errors[0]["desc"];
    return errorMessage(errMessage, duration);
  } else if (error.response?.data?.message) {
    errMessage = error.response?.data?.message;
    if (errMessage === "Unauthorized") return;
    return errorMessage(errMessage, duration);
  } else if (error.request) {
    // The request was made but no response was received
    errMessage = "Not Found";
    return warnMessage(errMessage);
  }
  // Something happened in setting up the request that triggered an Error
  errMessage = error.message;
  return errorMessage(errMessage, duration);
};

export const defaultNgiWarnMethod = error => {
  let errMessage;
  if (error.response) {
    // Request made and server responded
    errMessage = error.response.data.errors[0]["desc"];
    return warnMessage(errMessage);
  } else if (error.request) {
    // The request was made but no response was received
    errMessage = "Not Found";
    return warnMessage(errMessage);
  }
  // Something happened in setting up the request that triggered an Error
  errMessage = error.message;
  return warnMessage(errMessage);
};

export const NgiErrorMethod = error => {
  let errMessage;

  if (error.response?.data.errors) {
    errMessage = error.response.data.errors[0].hasOwnProperty("items")
      ? `${error.response.data.errors[0]["desc"]} ${error.response.data.errors[0]["items"][0]}`
      : error.response.data.errors[0]["desc"];
  } else if (error.response?.data.message) {
    errMessage = error.response.data.message;
  } else if (error.request) {
    // The request was made but no response was received
    errMessage = "Not Found";
  } else {
    // Something happened in setting up the request that triggered an Error
    errMessage = error.message;
  }

  return errMessage;
};

export const openNotificationWithIcon = (type, sms) => {
  notification[type]({
    message: type.charAt(0).toUpperCase() + type.slice(1),
    description: sms,
    duration: 2,
  });
};

export const phoneBeautifier = phone => {
  let result;
  const first = phone.slice(0, 1);
  const last = phone.slice(1);

  if (first === "0") result = `254${last}`;
  else if (first === "+") result = last;
  else result = phone;

  return { customerPhone: result };
};
