import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "antd";
import moment from "moment";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    width: "100%",
    background: "#FFF!important",
    border: "2px solid #444444",
    padding: 14,
    borderRadius: 30,

    "&>input": {
      background: "#F5F5F5!important",
    },
    "&.ant-picker:hover": {
      borderColor: "#444444",
      borderRightWidth: "initial",
    },
    "&input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  white: {
    background: "#FFF!important",
  },
});

const DEFAULT_DATEFORMAT = "YYYY-MM-DD";

const NGIDatePicker = ({
  disableDates,
  disabledPasDates = false,
  disabledFutureDates = false,
  dateFormat = DEFAULT_DATEFORMAT,
  allowClear = false,
  white = false,
  ...props
}) => {
  const classes = useStyles();

  const disabledPasDatesValidation = useCallback(current => moment().add(-1, "days") >= current, []);
  const disabledFutureDatesValidation = useCallback(current => moment().add(0, "days") <= current, []);

  return (
    <DatePicker
      {...props}
      allowClear={allowClear}
      inputReadOnly={true}
      className={classnames(classes.root, white && classes.white)}
      disabledDate={
        disableDates
          ? disableDates
          : disabledPasDates
          ? disabledPasDatesValidation
          : disabledFutureDates
          ? disabledFutureDatesValidation
          : false
      }
      format={dateFormat}
    />
  );
};
export default NGIDatePicker;
