import mt_pallet from "assets/images/svg/mt-pallet.svg";
import mt_box from "assets/images/svg/box-icon.svg";
import meter from "assets/images/svg/meter-icon.svg";
import battery from "assets/images/svg/battery-icon.svg";
import stove from "assets/images/svg/stove-icon.svg";
import cylinder from "assets/images/svg/cylinder-icon.svg";

export const searchOptions = [
  {
    value: "status",
    title: "Status",
  },
  {
    value: "trackingNumber",
    title: "Tracking Number",
  },
  {
    value: "toTenant",
    title: "Receiving Tenant",
  },
  {
    value: "id",
    title: "Shipment ID",
  },
];

export const STATUS = ["Open", "Cancelled", "Removed", "Packed", "InTransit", "Received"];

export const STATUS_COLOR_MAP = {
  Open: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  Cancelled: {
    //need change
    color: "#db6605",
    backgroundColor: "#ffe3cc",
  },
  Removed: {
    //need change
    color: "#db6605",
    backgroundColor: "#ffe3cc",
  },
  Packed: {
    //need change
    color: "#db6605",
    backgroundColor: "#ffe3cc",
  },
  InTransit: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  Received: {
    color: "#19c346",
    backgroundColor: "#d7ffe3",
  },
};

export const SHIPMENT_TYPES = ["Pallet", "Box", "Individually"];

export const IMG_MAP = {
  Pallet: mt_pallet,
  Box: mt_box,
  Meter: meter,
  Stove: stove,
  Battery: battery,
  Cylinder: cylinder,
};

export const ALLOWED_STATUSES = {
  Meter: [
    "Available",
    "Ready for Market",
    "Healthy",
    "Verified",
    "Not Ready",
    "Faulty",
    "Awaiting Testing New",
    "Awaiting Testing From Market",
    "Awaiting Configuration",
  ],
  Cylinder: ["Available", "Full, Not Ready", "Ready For Filling"],
  Stove: ["Available", "New", "Refurbished", "Not Ready", "Used", "Damaged"],
  Battery: ["Available", "Full"],
};
