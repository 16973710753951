import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE } from "utils/constants";
import { palletListColumns } from "./PalletListColumns";
import PalletSearch from "./PalletSearch";
import { deleteMTContainer, fetchMTShippingContainers } from "../../../utils/api";
import { TYPE } from "../constants";
import ConfirmationModal from "../../../components/Common/modals/ConfirmationModal";
import { getDictionary } from "../../../store/dictionaries/actions";
import { DICTIONARY_TYPES } from "../../../store/dictionaries/constants";
import { useDispatch } from "react-redux";

const PalletsList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.MT_PALLETS);
  const [currentContainer, setCurrentContainer] = useState(null);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Pallets were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    fetchMTShippingContainers(TYPE.PALLETS, { depot: AUTH_USER?.depotName, ...filters })
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const onDelete = useCallback(() => {
    closeDeleteModal();
    setLoader(true);
    deleteMTContainer(currentContainer)
      .then(() => {
        setTimeout(() => {
          setLoader(false);
          successMessage("Pallet deleted successfully");
          handleSearch();
        }, 3000);
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
  }, [currentContainer]);

  const openDeleteModal = useCallback(id => setCurrentContainer(id), []);
  const closeDeleteModal = useCallback(() => setCurrentContainer(null), []);

  const columns = useMemo(() => palletListColumns(openDeleteModal), [openDeleteModal]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons"></div>

            <PalletSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.MT_PALLETS}
              rowSelection={null}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
          {currentContainer && (
            <ConfirmationModal
              onCancel={closeDeleteModal}
              onConfirm={onDelete}
              description="Are you sure you want to delete the pallet?"
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default PalletsList;
