import React, { useCallback, useMemo } from "react";
import { Col, Form, Modal, Row, Space } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import Title from "antd/lib/typography/Title";
import { VALIDATION_RULES } from "pages/meter-management/meters/variables";
import { Option } from "antd/es/mentions";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "../../../store/dictionaries/selectors";
import NGISelect from "../../../components/Common/inputs/NGISelect";

const useStyles = makeStyles({
  root: {
    maxWidth: "350px!important",

    "& .ant-modal-body": {
      padding: "24px 30px!important",
    },
  },
  checkboxes: {
    display: "flex",
    flexDirection: "column",

    "&>label": {
      marginLeft: "0!important",
    },
  },
  photoContainer: {
    marginBottom: 0,
  },
});

const NewInspectionTaskReasonsModal = ({ onClose, reason = "", type, onSave, allReasons }) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const {
    list: { taskCategory },
  } = useSelector(DictionariesSelector);

  const allReasonsNames = useMemo(() => allReasons.map(item => item.reason.toLowerCase()), [allReasons]);
  const onSubmit = useCallback(values => {
    onSave(values.reason, values.type);
  }, []);

  return (
    <Modal open={true} className={classes.root} closable={false} onCancel={onClose} footer={[]}>
      <div>
        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={{
            reason,
            type,
          }}
        >
          <Row>
            <Col span={24}>
              <Title level={5}>Reason Name</Title>
              <Form.Item
                name="reason"
                rules={[
                  ...VALIDATION_RULES,
                  {
                    message: "The reason has already existed",
                    validator: (_, value) => {
                      if (
                        allReasonsNames.includes(value.toLowerCase()) &&
                        reason.toLowerCase() !== value.toLowerCase()
                      ) {
                        return Promise.reject("The reason has already existed");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIInput disablePattern placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Title level={5}>Task Type</Title>
              <Form.Item name="type">
                <NGISelect allowClear showSearch placeholder={"Select value"}>
                  {taskCategory &&
                    taskCategory?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </NGISelect>
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="Save" size="small" />
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default NewInspectionTaskReasonsModal;
