import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { shipmentListColumns } from "./ShipmentListColumns";
import ShipmentSearch from "./ShipmentSearch";
import { fetchMTOrders } from "../../../utils/api";
import CustomButton from "../../../components/Common/buttons/CustomButton";
import NewOrderModal from "./newOrder/NewOrderModal";
import { getDictionary } from "../../../store/dictionaries/actions";
import { DICTIONARY_TYPES } from "../../../store/dictionaries/constants";
import { useDispatch } from "react-redux";

const ShipmentsList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.MT_ORDERS);

  const closeModal = useCallback(() => setModalOpen(false), []);
  const openModal = useCallback(() => setModalOpen(true), []);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.TENANT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Orders were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    fetchMTOrders(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => shipmentListColumns(), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                onClick={openModal}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="New Shipment"
              />
            </div>

            <ShipmentSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.MT_ORDERS}
              rowSelection={null}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>

          {modalOpen && <NewOrderModal onClose={closeModal} update={handleSearch} />}
        </Container>
      </div>
    </>
  );
};

export default ShipmentsList;
