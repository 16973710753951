export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const TASK_TYPES = [
  // "Meter Change",
  "Cylinder Change",
  "Battery Change",
  "Stove Change",
  "Inspection",
  "Package Upgrade",
  "Repossession",
  "Pipe Change",
  "Safety",
  "Investigation",
  "Asset Swap",
  "Meter Inspection",
];
