import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import editIcon from "assets/images/svg/Pencil Drawing.svg";
import addIcon from "assets/images/svg/plus-circle.svg";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { createDictionary } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { getFormattedKeyName } from "utils/helpers/functions";
import NGIPrompt from "components/Common/NGIPrompt";
import NewRepossessionReasonModal from "pages/operations/repossessionReasons/NewRepossessionReasonModal";
import { clearReasons } from "pages/operations/meterChangeReasons/utils";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  builderContainer: {
    width: "50%",

    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  reasonItem: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    padding: 20,
    marginTop: 30,
    marginBottom: 15,
  },
  subItemContainer: {
    paddingLeft: 40,
    marginLeft: 15,
    position: "relative",

    "&:before": {
      content: "''",
      width: 1,
      height: "100%",
      position: "absolute",
      top: 0,
      left: 6,
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
    "&:last-child:before": {
      height: 46,
    },

    "&:after": {
      content: "''",
      width: 20,
      height: 1,
      position: "absolute",
      top: 45,
      left: 7,
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
  },
  reasonSubItem: {
    paddingTop: 15,
  },
  subItemTitle: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    padding: 20,
  },
  imgBtn: {
    cursor: "pointer",
    marginLeft: 10,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  noReasons: {
    marginTop: 20,
  },
  requiredFields: {
    opacity: 0.5,
    fontSize: 12,
  },
  buttons: {
    width: 80,
  },
});

const RepossessionReasons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentReason, setCurrentReason] = useState("");
  const [reasons, setReasons] = useState([]);
  const [allReasons, setAllReasons] = useState([]);
  const [isSub, setIsSub] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const {
    list: { repossessionTaskReasonV2 },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.REPOSSESSION_REASONS_V2));
  }, []);

  useEffect(() => {
    if (repossessionTaskReasonV2?.length) {
      setReasons(repossessionTaskReasonV2);
    }
  }, [repossessionTaskReasonV2]);

  const editReason = useCallback(
    event => {
      setCurrentReason(event.currentTarget.dataset.reason);
      setAllReasons(reasons);
      setIsOpen(true);
    },
    [reasons]
  );

  const editSubReason = useCallback(
    event => {
      setCurrentReason(event.currentTarget.dataset.reason);
      const [level1] = event.currentTarget.dataset.reason.split(">");
      const l1Index = reasons.findIndex(item => item.item === level1);
      setAllReasons(reasons[l1Index].items);
      setIsSub(true);
      setIsOpen(true);
    },
    [reasons]
  );

  const addSubReason = useCallback(
    event => {
      const [level1] = event.currentTarget.dataset.reason.split(">");
      const l1Index = reasons.findIndex(item => item.item === level1);
      setAllReasons(reasons[l1Index].items);
      setIsSub(true);
      setCurrentReason(event.currentTarget.dataset.reason);
      setIsOpen(true);
    },
    [reasons]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsSub(false);
    setAllReasons([]);
    setCurrentReason("");
  }, []);

  const handleAddNew = useCallback(() => {
    setAllReasons(reasons);
    setIsOpen(true);
  }, [reasons]);

  const handleSaveReasons = useCallback(async () => {
    const formattedData = {
      name: DICTIONARY_TYPES.REPOSSESSION_REASONS_V2,
      isMultiLevel: true,
      items: clearReasons(reasons),
    };

    setLoader(true);
    await createDictionary(formattedData)
      .then(() => {
        setLoader(false);
        successMessage("Reasons saved successfully");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [reasons]);

  const handleSave = useCallback(
    newReason => {
      setDisabled(false);
      const [level1, level2] = newReason.split(">");
      const [oldLevel1, oldLevel2] = currentReason.split(">");

      setReasons(prevState => {
        const state = [...prevState];
        const l1Index = state.findIndex(item => item.item === oldLevel1);
        if (level2) {
          const l2Index = state[l1Index].items.findIndex(item => item.item === oldLevel2);
          if (l2Index !== -1) {
            state[l1Index].items[l2Index].item = level2;
          } else {
            state[l1Index].items.push({
              item: level2,
            });
          }
        } else {
          if (l1Index !== -1) {
            state[l1Index].item = level1;
          } else {
            state.push({
              items: [],
              item: level1,
            });
          }
        }
        return state;
      });
      handleClose();
    },
    [currentReason]
  );

  return (
    <>
      <NGIPrompt show={!disabled} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <div className={classes.root}>
                <div className={classes.cardBody}>
                  <div className={classes.buttonsContainer}>
                    <CustomButton
                      icon={<i className="bx bx-plus-circle" />}
                      type="primary"
                      text="NEW REASON"
                      onClick={handleAddNew}
                    />
                    <CustomButton type="primary" text="Save" disabled={disabled} onClick={handleSaveReasons} />
                  </div>
                  <div className={classes.builderContainer}>
                    {reasons.map(item => (
                      <div key={item}>
                        <div className={classes.reasonItem}>
                          <div>{item.item}</div>
                          <div className={classes.buttons}>
                            <img
                              data-reason={item.item}
                              className={classes.imgBtn}
                              onClick={editReason}
                              src={editIcon}
                              alt="action-icon"
                            />
                            <img
                              data-reason={item.item}
                              className={classes.imgBtn}
                              onClick={addSubReason}
                              src={addIcon}
                              alt="action-icon"
                            />
                          </div>
                        </div>
                        {!!item?.items?.length &&
                          item?.items?.map(subItem => (
                            <div key={`${item.item}>${subItem.item}`} className={classes.subItemContainer}>
                              <div className={classes.reasonSubItem}>
                                <div className={classes.subItemTitle}>
                                  <div>
                                    {subItem.item}
                                    <span className={classes.requiredFields}>
                                      {` ${
                                        subItem?.requiredRepairFields?.length || subItem?.isPhotoRequired
                                          ? `(Required fields: ${[
                                              ...(subItem?.requiredRepairFields || []),
                                              subItem?.isPhotoRequired && "Photo",
                                            ]
                                              .filter(row => row)
                                              .map(getFormattedKeyName)
                                              .join(", ")})`
                                          : ""
                                      }`}
                                    </span>
                                  </div>
                                  <div>
                                    <img
                                      data-reason={`${item.item}>${subItem.item}`}
                                      className={classes.imgBtn}
                                      onClick={editSubReason}
                                      src={editIcon}
                                      alt="action-icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                  {!reasons.length && (
                    <Card className={classes.noReasons}>
                      <CardBody className="text-center">
                        <i className="bx bx-warning" />
                        <h5>No reasons</h5>
                        <p className="text-muted">Please add new reason</p>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </div>
            </>
          </Spin>
        </Container>
        {isOpen && (
          <NewRepossessionReasonModal
            onClose={handleClose}
            reason={currentReason}
            onSave={handleSave}
            isSub={isSub}
            allReasons={allReasons}
          />
        )}
      </div>
    </>
  );
};

export default RepossessionReasons;
