export const searchOptions = [
  {
    value: "status",
    title: "Status",
  },
  {
    value: "assetGroup",
    title: "Asset Type",
  },
  {
    value: "date",
    title: "Created At",
  },
  {
    value: "boxId",
    title: "Box ID",
  },
  {
    value: "palletId",
    title: "Pallet ID",
  },
];

export const STATUS = ["Available", "Packed"];

export const STATUS_COLOR_MAP = {
  Available: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  Packed: {
    color: "#0085FF",
    backgroundColor: "#E7F5FF",
  },
};
