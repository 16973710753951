import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { retrieveSTODetails, updateTripSTO } from "utils/api";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import classnames from "classnames";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import { ORDER_TYPES, VALIDATION_RULES } from "pages/stock-management/stockTransferOrders/NewSTO/constants";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { v4 as uuidv4 } from "uuid";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch, useSelector } from "react-redux";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import { USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { uniq } from "lodash";
import { userHasOneOfRoles } from "utils/helpers/functions";
import moment from "moment";
import EditAssetRow from "pages/stock-management/trips/editTrip/editSTO/EditAssetRow";
import { getPreparedData, isIncrease } from "pages/stock-management/trips/editTrip/utils";
import { TRIP_STATUSES } from "pages/stock-management/trips/constants";
import ConfirmationUpdateModal from "pages/stock-management/trips/editTrip/editSTO/ConfirmationUpdateModal";
import { UNASSIGN_CREATE_TYPE, UPDATE_REASONS } from "pages/stock-management/trips/editTrip/constants";
import { STO_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import { groupBy } from "lodash";
import NotFoundContainer from "components/Common/NotFoundContainer";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col-12 + .ant-col-12": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAsset: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  orderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    color: "#4d4d4d",
    marginBottom: 10,
  },
  outbound: {
    color: "#ff3714",
  },
  inbound: {
    color: "#1bc44a",
  },
  redBorder: {
    borderColor: "#ff3714ba",
  },
  greenBorder: {
    borderColor: "#1bc44a91",
  },
  depotName: {
    color: "black",
    fontWeight: 600,
    width: "25%",
  },
  assetType: {
    color: "#4d4d4d",
  },
  condition: {
    color: "#4d4d4d",
  },
  qty: {
    fontWeight: 600,

    "&>span": {
      fontWeight: 300,
    },
  },
});

const EditSTOModal = ({
  stoId = "new",
  trip,
  additionalData,
  stop,
  onClose,
  update,
  createType,
  editId,
  setChangedList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isNew = stoId === "new";

  const [isLoading, setIsLoading] = useState(false);
  const [STOData, setSTOData] = useState(null);
  const [assets, setAssets] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [newType, setNewType] = useState(null);
  const [allowTypeChangeConfirmation, setAllowTypeChangeConfirmation] = useState(false);
  const [selectedFromDepot, setSelectedFromDepot] = useState("");
  const [selectedToDepot, setSelectedToDepot] = useState("");

  const [updateOption, setUpdateOption] = useState("");
  const [increaseState, setIncreaseState] = useState(null);
  const [inTransitState, setInTransitState] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [disableMap, setDisableMap] = useState({
    toDepot: !isNew && createType === UNASSIGN_CREATE_TYPE.CREATE_NEW,
    fromDepot: !isNew,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [depotListTo, setDepotListTo] = useState((!isNew && additionalData?.availableDepotTo) || null);
  const [depotListFrom, setDepotListFrom] = useState((!isNew && additionalData?.availableDepotFrom) || null);

  const [showDecreaseConfirm, setShowDecreaseConfirm] = useState(0);
  const [showDecreaseNewInboundConfirm, setShowDecreaseNewInboundConfirm] = useState(0);
  const editedItem = useMemo(() => assets?.find(item => item.id === editId), [assets]);
  const initItem = useMemo(() => STOData?.items?.find(item => item.id === editId), [STOData]);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    if (!isNew) {
      setIsLoading(true);
      retrieveSTODetails(stoId).then(({ data }) => {
        const { items, preferredDate, type } = data?.result;
        form.setFieldsValue({
          ...data?.result,
          preferredDate: moment(preferredDate),
          toDepot: createType === UNASSIGN_CREATE_TYPE.RECREATE ? null : data?.result?.toDepot,
          ...(createType === UNASSIGN_CREATE_TYPE.OUTBOUND_DEDUCTION && { type: STO_TYPES.INBOUND }),
        });
        setSTOData(structuredClone(data?.result));
        setSelectedType(type);
        setAssets(items);
        setIsLoading(false);
        if (createType === UNASSIGN_CREATE_TYPE.OUTBOUND_DEDUCTION) {
          const currentAsset = items?.find(item => item.id === editId);
          setInTransitState({
            _quantityLeft: currentAsset?.quantity,
          });
          const listTo = trip.stops
            .filter(
              item =>
                item.status !== "Passed" &&
                item.assets.find(
                  asset =>
                    asset.condition === currentAsset?.condition &&
                    asset.group === currentAsset?.group &&
                    asset.stoType === STO_TYPES.INBOUND
                )
            )
            .map(item => item.location.depot);
          setDepotListTo(listTo);
          if (!listTo.length) setIsNotFound(true);
        }
        if (createType === "updateCorrespondingInbound" || createType === "createNew") {
          form.setFieldsValue({
            toDepot: null,
          });
          const currentAsset = items?.find(item => item.id === editId);
          setInTransitState({
            _quantityLeft: currentAsset?.quantity,
          });

          if (createType === "updateCorrespondingInbound") {
            const listTo = trip.stops
              .filter(
                item =>
                  item.status !== "Passed" &&
                  item.location.depot !== data.result.toDepot &&
                  item.assets.find(
                    asset =>
                      asset.condition === currentAsset?.condition &&
                      asset.group === currentAsset?.group &&
                      asset.stoType === STO_TYPES.INBOUND
                  )
              )
              .map(item => item.location.depot);
            setDepotListTo(listTo);
            if (!listTo.length) setIsNotFound(true);
          }
        }
      });
    }
    if (trip?.startDate) {
      form.setFieldsValue({
        preferredDate: moment(trip.startDate),
      });
    }
  }, [stoId, createType]);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const isAssetQuantityZero = useCallback(() => {
    if (assets[0]?.quantity === 0 || !assets[0]?.quantity) {
      warnMessage("Asset Quantity cannot be 0.");
      return true;
    }
    return false;
  }, [assets]);

  const onSave = useCallback(
    values => {
      const preparedData = getPreparedData(trip?.status, {
        editedItem,
        stop,
        newValues: values,
        createType,
        isNew,
        allAssets: assets,
        inTransitState,
        initItem,
        trip,
        increaseState,
        selectedType,
      });

      setIsLoading(true);
      updateTripSTO(preparedData, trip?.tripNumber)
        .then(res => {
          const {
            result: { changes },
          } = res.data;
          setChangedList(changes.map(item => item.stoItemId));
          successMessage(`STO was ${isNew ? "created" : "updated"} successfully`);
          setIsLoading(false);
          setTimeout(() => update && update(), 1500);
          onClose();
        })
        .catch(e => {
          setIsLoading(false);
          defaultNgiErrorMethod(e);
        });
    },
    [assets, stop, trip, createType, inTransitState, isNew, editedItem, initItem, increaseState, selectedType]
  );

  const onUpdateInTransitConfirm = useCallback(
    value => {
      const values = form.getFieldsValue();
      let listTo = [];
      let qLeft = 0;
      setUpdateOption(value);

      if (value === "createNew") {
        const hideList = trip.stops
          .filter((item, k) => ["Passed"].includes(item.status) && k !== 0)
          .map(item => item.location.depot);

        listTo =
          depot[USER_TENANT_NAME] &&
          uniq(depot[USER_TENANT_NAME])?.filter(item => ![...hideList, values.fromDepot].includes(item));
      }
      if (value === "updateCorresponding" || value === "updateCorrespondingInbound") {
        listTo = trip.stops
          .filter(
            item =>
              item.status !== "Passed" &&
              (value === "updateCorrespondingInbound" ? item.location.depot !== STOData.toDepot : true) &&
              item.assets.find(
                asset =>
                  asset.condition === editedItem?.condition &&
                  asset.group === editedItem?.group &&
                  asset.stoType === STO_TYPES.INBOUND
              )
          )
          .map(item => item.location.depot);
      }
      setDepotListTo(listTo);
      if (!listTo.length) setIsNotFound(true);

      if (selectedType === STO_TYPES.OUTBOUND) {
        qLeft = inTransitState?._quantityLeft
          ? inTransitState?._quantityLeft - editedItem?.quantity
          : editedItem?.quantity - initItem.quantity;
      }
      if (selectedType === STO_TYPES.INBOUND) {
        qLeft = inTransitState?._quantityLeft
          ? inTransitState?._quantityLeft - editedItem.quantity
          : initItem.quantity - editedItem.quantity;
      }

      setInTransitState({
        _quantityLeft: qLeft,
        ...(values.toDepot &&
          values.toDepot !== STOData.toDepot && {
            compensatoryItems: [
              ...(inTransitState?.compensatoryItems || []),
              {
                isNew: updateOption === "createNew",
                depot: values.toDepot,
                address: " ",
                quantity: editedItem?.quantity,
              },
            ],
          }),
      });

      setAssets([
        {
          ...editedItem,
          quantity: qLeft,
        },
      ]);

      form.setFieldsValue({
        fromDepot: STOData.fromDepot,
        toDepot: null,
        type: STO_TYPES.INBOUND,
      });
      setDisableMap({ toDepot: false, fromDepot: true });
      setIsUpdateOpen(false);
    },
    [form, inTransitState, STOData, editedItem, initItem, updateOption]
  );

  const onDecreaseInTransit = useCallback(() => {
    const values = form.getFieldsValue();
    let qLeft = 0;
    let listTo = [];

    if (selectedType === STO_TYPES.OUTBOUND) {
      qLeft = inTransitState?._quantityLeft
        ? inTransitState?._quantityLeft - editedItem?.quantity
        : initItem.quantity - editedItem?.quantity;

      listTo = trip.stops
        .filter(
          item =>
            item.status !== "Passed" &&
            item.assets.find(
              asset =>
                asset.condition === editedItem?.condition &&
                asset.group === editedItem?.group &&
                asset.stoType === STO_TYPES.INBOUND
            )
        )
        .map(item => item.location.depot);
    }
    if (selectedType === STO_TYPES.INBOUND) {
      qLeft = inTransitState?._quantityLeft
        ? inTransitState?._quantityLeft - editedItem.quantity
        : editedItem.quantity - initItem.quantity;

      listTo = trip.stops
        .filter(
          item =>
            item.status !== "InProgress" &&
            item.status !== "Passed" &&
            item.location.depot !== STOData.toDepot &&
            item.assets.find(
              asset =>
                asset.condition === editedItem?.condition &&
                asset.group === editedItem?.group &&
                asset.stoType === STO_TYPES.INBOUND
            )
        )
        .map(item => item.location.depot);
    }

    setDepotListTo(listTo);
    if (!listTo.length) setIsNotFound(true);

    setInTransitState({
      _quantityLeft: qLeft,
      ...(values.toDepot &&
        values.toDepot !== STOData.toDepot && {
          compensatoryItems: [
            ...(inTransitState?.compensatoryItems || []),
            {
              depot: values.toDepot,
              address: " ",
              quantity: editedItem.quantity,
            },
          ],
        }),
    });
    setAssets([
      {
        ...editedItem,
        quantity: qLeft,
      },
    ]);

    form.setFieldsValue({
      fromDepot: STOData.fromDepot,
      toDepot: null,
      type: STO_TYPES.INBOUND,
    });
    setDisableMap({ toDepot: false, fromDepot: true });
    setShowDecreaseConfirm(0);
  }, [form, inTransitState, STOData, editedItem, initItem]);

  const onSaveNewInbound = useCallback(() => {
    if (isAssetQuantityZero()) return;

    const qLeft = inTransitState?._quantityLeft
      ? inTransitState?._quantityLeft - assets[0]?.quantity
      : assets[0]?.quantity;
    const values = form.getFieldsValue();

    setShowDecreaseNewInboundConfirm(qLeft);

    if (!qLeft) {
      if (values.toDepot) {
        setInTransitState({
          _quantityLeft: qLeft,
          ...(values.toDepot && {
            compensatoryItems: [
              ...(inTransitState?.compensatoryItems || []),
              {
                depot: values.toDepot,
                address: " ",
                quantity: assets[0]?.quantity,
              },
            ],
          }),
        });
        setShowSummary(true);
      } else {
        form.setFields([
          {
            name: "toDepot",
            errors: ["Please enter value"],
          },
        ]);
      }
    } else {
      setShowDecreaseNewInboundConfirm(qLeft);
    }
  }, [inTransitState, assets, form, isAssetQuantityZero]);

  const onDecreaseNewInbound = useCallback(() => {
    const values = form.getFieldsValue();
    const qLeft = inTransitState?._quantityLeft
      ? inTransitState?._quantityLeft - assets[0]?.quantity
      : assets[0]?.quantity;

    const listTo = trip.stops
      .filter(
        item =>
          item.status !== "InProgress" &&
          item.status !== "Passed" &&
          item.assets.find(
            asset =>
              asset.condition === assets[0]?.condition &&
              asset.group === assets[0]?.group &&
              asset.stoType === STO_TYPES.INBOUND
          )
      )
      .map(item => item.location.depot);

    setDepotListTo(listTo);
    if (!listTo.length) setIsNotFound(true);

    setInTransitState({
      _quantityLeft: qLeft,
      ...(values.toDepot && {
        compensatoryItems: [
          ...(inTransitState?.compensatoryItems || []),
          {
            depot: values.toDepot,
            address: " ",
            quantity: assets[0]?.quantity,
          },
        ],
      }),
    });
    setAssets([
      {
        ...assets[0],
        quantity: qLeft,
      },
    ]);

    form.setFieldsValue({
      fromDepot: null,
      toDepot: null,
      type: STO_TYPES.INBOUND,
    });
    setDisableMap({ toDepot: false, fromDepot: true, type: true, assets: true });
    setShowDecreaseNewInboundConfirm(0);
  }, [form, inTransitState, editedItem, initItem, assets]);

  const onUpdateClose = useCallback(() => {
    setIsUpdateOpen(false);
    setIncreaseState(null);
  }, []);

  const onUpdateSubmit = useCallback(() => {
    if (isAssetQuantityZero()) return;
    const isIncreaseCase = increaseState !== null ? increaseState : isIncrease(initItem.quantity, editedItem?.quantity);
    const values = form.getFieldsValue();

    if (increaseState === null) {
      setIncreaseState(isIncreaseCase);
    }

    if (isIncreaseCase) {
      if (selectedType === STO_TYPES.OUTBOUND) {
        const qLeft = inTransitState?._quantityLeft
          ? inTransitState?._quantityLeft - editedItem.quantity
          : editedItem.quantity - initItem.quantity;

        if (!qLeft) {
          if (values.toDepot) {
            setInTransitState({
              _quantityLeft: qLeft,
              ...(values.toDepot && {
                compensatoryItems: [
                  ...(inTransitState?.compensatoryItems || []),
                  {
                    isNew: updateOption === "createNew",
                    depot: values.toDepot,
                    address: " ",
                    quantity: editedItem.quantity,
                  },
                ],
              }),
            });
            setShowSummary(true);
          } else {
            form.setFields([
              {
                name: "toDepot",
                errors: ["Please enter value"],
              },
            ]);
          }
        } else {
          setIsUpdateOpen(true);
        }
        return;
      }
      if (selectedType === STO_TYPES.INBOUND) {
        const qLeft = inTransitState?._quantityLeft
          ? inTransitState?._quantityLeft - editedItem.quantity
          : editedItem.quantity - initItem.quantity;
        if (!qLeft) {
          if (values.toDepot) {
            setInTransitState({
              _quantityLeft: qLeft,
              ...(values.toDepot && {
                compensatoryItems: [
                  ...(inTransitState?.compensatoryItems || []),
                  {
                    depot: values.toDepot,
                    address: " ",
                    quantity: editedItem.quantity,
                  },
                ],
              }),
            });
            setShowSummary(true);
          } else {
            form.setFields([
              {
                name: "toDepot",
                errors: ["Please enter value"],
              },
            ]);
          }
        } else {
          setShowDecreaseConfirm(qLeft);
        }
      }
    } else {
      if (selectedType === STO_TYPES.OUTBOUND) {
        const qLeft = inTransitState?._quantityLeft
          ? inTransitState?._quantityLeft - editedItem.quantity
          : initItem.quantity - editedItem.quantity;

        if (!qLeft) {
          if (values.toDepot) {
            setInTransitState({
              _quantityLeft: qLeft,
              ...(values.toDepot && {
                compensatoryItems: [
                  ...(inTransitState?.compensatoryItems || []),
                  {
                    depot: values.toDepot,
                    address: " ",
                    quantity: editedItem.quantity,
                  },
                ],
              }),
            });
            setShowSummary(true);
          } else {
            form.setFields([
              {
                name: "toDepot",
                errors: ["Please enter value"],
              },
            ]);
          }
        } else {
          setShowDecreaseConfirm(qLeft);
        }
      }
      if (selectedType === STO_TYPES.INBOUND) {
        const qLeft = inTransitState?._quantityLeft
          ? inTransitState?._quantityLeft - editedItem.quantity
          : initItem.quantity - editedItem.quantity;

        if (!qLeft) {
          if (values.toDepot) {
            setInTransitState({
              _quantityLeft: qLeft,
              ...(values.toDepot && {
                compensatoryItems: [
                  ...(inTransitState?.compensatoryItems || []),
                  {
                    isNew: updateOption === "createNew",
                    depot: values.toDepot,
                    address: " ",
                    quantity: editedItem.quantity,
                  },
                ],
              }),
            });
            setShowSummary(true);
          } else {
            form.setFields([
              {
                name: "toDepot",
                errors: ["Please enter value"],
              },
            ]);
          }
        } else {
          setIsUpdateOpen(true);
        }
      }
    }
  }, [assets, STOData, selectedType, initItem, editedItem, increaseState, updateOption, form, isAssetQuantityZero]);

  const openConfirmationModal = useCallback(() => {
    if (isAssetQuantityZero()) return;
    setIsConfirmationOpen(true);
  }, [isAssetQuantityZero]);

  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const openConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(true), []);
  const closeConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(false), []);

  const openConfirmTypeChangeModal = useCallback(() => setAllowTypeChangeConfirmation(true), []);
  const closeConfirmTypeChangeModal = useCallback(() => setAllowTypeChangeConfirmation(false), []);

  const closeConfirmDecreaseModal = useCallback(() => {
    setShowDecreaseConfirm(0);
    setIncreaseState(null);
  }, []);

  const closeConfirmDecreaseNewInboundModal = useCallback(() => {
    setShowDecreaseNewInboundConfirm(0);
    setIncreaseState(null);
  }, []);

  const onAddAsset = useCallback(() => setAssets(prevState => [...prevState, { id: uuidv4() }]), []);
  const onRemoveAsset = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setAssets(prevState => prevState.filter(item => item.id !== id));
  }, []);

  const handleTypeChange = useCallback(
    value => {
      if (selectedType && assets.length) {
        openConfirmTypeChangeModal();
        setNewType(value);
        form.setFieldValue("type", selectedType);
      } else {
        setSelectedType(value);
        form.setFieldsValue({
          toDepot: null,
          fromDepot: null,
        });
      }
      setDisableMap(prevState => ({
        ...prevState,
        toDepot: false,
        fromDepot: value === STO_TYPES.INBOUND && trip?.status === TRIP_STATUSES.IN_TRANSIT,
      }));
    },
    [selectedType, assets, form]
  );

  const onTypeChanged = useCallback(() => {
    closeConfirmTypeChangeModal();
    form.setFieldsValue({
      type: newType,
      toDepot: null,
      fromDepot: null,
    });
    setSelectedFromDepot("");
    setSelectedToDepot("");
    setSelectedType(newType);
    setAssets([]);
    setNewType(null);
  }, [newType, form]);

  const onSubmit = useCallback(() => {
    showSummary ? onSave() : form.submit();
    closeConfirmationModal();
  }, [form, showSummary]);

  const onFromChanged = useCallback(value => setSelectedFromDepot(value), []);

  const onToChanged = useCallback(
    value => {
      setSelectedToDepot(value);
      if (!isNew) {
        switch (trip?.status) {
          case TRIP_STATUSES.IN_TRANSIT:
            if (
              (selectedType === STO_TYPES.OUTBOUND && createType === UNASSIGN_CREATE_TYPE.OUTBOUND_DEDUCTION) ||
              (selectedType === STO_TYPES.INBOUND && createType === UNASSIGN_CREATE_TYPE.CREATE_NEW)
            ) {
              const addedCount =
                inTransitState?.compensatoryItems?.reduce((a, i) => {
                  if (i.depot === value) {
                    return a + i.quantity;
                  }
                  return a;
                }, 0) || 0;

              const foundStop = trip?.stops.find(
                i => i.status !== "Passed" && i.location.depot === value && i.orderedInAssets.length
              );
              const foundAssetMaxCount =
                foundStop?.orderedInAssets?.find(
                  asset => asset.condition === editedItem.condition && asset.group === editedItem.group
                )?.quantity || 0;
              const maxValueAllowed = addedCount ? foundAssetMaxCount - addedCount : foundAssetMaxCount;
              if (maxValueAllowed < inTransitState?._quantityLeft) {
                setMaxValue(maxValueAllowed);
                setAssets([
                  {
                    ...editedItem,
                    quantity: maxValueAllowed,
                  },
                ]);
              } else {
                setMaxValue(inTransitState?._quantityLeft);
                setAssets([
                  {
                    ...editedItem,
                    quantity: inTransitState?._quantityLeft,
                  },
                ]);
              }
            }
            break;
          default:
            break;
        }
      }
      if (isNew && trip?.status === TRIP_STATUSES.IN_TRANSIT && selectedType === STO_TYPES.INBOUND) {
        const addedCount =
          inTransitState?.compensatoryItems?.reduce((a, i) => {
            if (i.depot === value) {
              return a + i.quantity;
            }
            return a;
          }, 0) || 0;
        const newItem = assets[0];
        const foundStop = trip?.stops.find(
          i => i.status !== "Passed" && i.location.depot === value && i.orderedInAssets.length
        );
        const foundAssetMaxCount =
          foundStop?.orderedInAssets?.find(
            asset => asset.condition === newItem.condition && asset.group === newItem.group
          )?.quantity || 0;

        const maxValueAllowed = addedCount ? foundAssetMaxCount - addedCount : foundAssetMaxCount;
        if (maxValueAllowed < inTransitState?._quantityLeft) {
          setMaxValue(maxValueAllowed);
          setAssets([
            {
              ...newItem,
              quantity: maxValueAllowed,
            },
          ]);
        } else {
          setMaxValue(inTransitState?._quantityLeft);
          setAssets([
            {
              ...newItem,
              quantity: inTransitState?._quantityLeft,
            },
          ]);
        }
      }
    },
    [trip, inTransitState, editedItem, createType]
  );

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.ASSET_TYPE_GROUP));
    dispatch(getDictionary(DICTIONARY_TYPES.STO_GROUP_CONDITIONS));
  }, []);

  const renderSummary = useCallback(() => {
    switch (trip.status) {
      case TRIP_STATUSES.IN_TRANSIT:
        if (selectedType === STO_TYPES.OUTBOUND) {
          const grouped = groupBy(inTransitState?.compensatoryItems, "depot");
          const updatedList = Object.keys(grouped).map(item => {
            const itemSum = grouped[item].reduce((a, i) => a + i.quantity, 0);
            return {
              ...grouped[item][0],
              quantity: itemSum,
            };
          });

          const sum = updatedList.reduce((a, i) => a + i.quantity, 0);
          const compensatoryItems = updatedList.map(item => {
            const foundStop = trip?.stops.find(
              i => i.status !== "Passed" && i.location.depot === item.depot && i.orderedInAssets.length
            );
            const foundAssetCount =
              foundStop?.orderedInAssets?.find(
                asset => asset.condition === editedItem.condition && asset.group === editedItem.group
              )?.quantity || 0;

            return foundStop && !item.isNew
              ? {
                  ...item,
                  quantity: increaseState ? item.quantity + foundAssetCount : foundAssetCount - item.quantity,
                }
              : item;
          });
          return (
            <div>
              <TitleWithDivider title="Outbound Order" />
              <div className={classnames(classes.orderContainer, classes.redBorder)}>
                <div className={classes.depotName}>{STOData.fromDepot}</div>
                <div className={classes.assetType}>{initItem.group}</div>
                <div className={classes.condition}>{initItem.condition}</div>
                <div className={classnames(classes.qty, classes.outbound)}>
                  {increaseState ? sum + initItem?.quantity : initItem?.quantity - sum} <span>Pcs</span>
                </div>
              </div>

              <TitleWithDivider title="Inbound Order(s)" />
              {compensatoryItems?.map(item => (
                <div key={item.depot} className={classnames(classes.orderContainer, classes.greenBorder)}>
                  <div className={classes.depotName}>{item.depot}</div>
                  <div className={classes.assetType}>{initItem.group}</div>
                  <div className={classes.condition}>{initItem.condition}</div>
                  <div className={classnames(classes.qty, classes.inbound)}>
                    {item.quantity} <span>Pcs</span>
                  </div>
                </div>
              ))}
            </div>
          );
        }
        if (selectedType === STO_TYPES.INBOUND && !isNew) {
          const grouped = groupBy(inTransitState?.compensatoryItems, "depot");
          const updatedList = Object.keys(grouped).map(item => {
            const itemSum = grouped[item].reduce((a, i) => a + i.quantity, 0);
            return {
              ...grouped[item][0],
              quantity: itemSum,
            };
          });

          const sum = updatedList.reduce((a, i) => a + i.quantity, 0);
          const compensatoryItems = updatedList.map(item => {
            const foundStop = trip?.stops.find(
              i => i.status !== "Passed" && i.location.depot === item.depot && i.orderedInAssets.length
            );
            const foundAssetCount =
              foundStop?.orderedInAssets?.find(
                asset => asset?.condition === editedItem?.condition && asset?.group === editedItem?.group
              )?.quantity || 0;

            return foundAssetCount > 0
              ? {
                  ...item,
                  quantity: increaseState ? foundAssetCount - item.quantity : item.quantity + foundAssetCount,
                }
              : item;
          });

          return (
            <div>
              <TitleWithDivider title="Inbound Order" />
              <div className={classnames(classes.orderContainer, classes.greenBorder)}>
                <div className={classes.depotName}>{STOData.toDepot}</div>
                <div className={classes.assetType}>{initItem.group}</div>
                <div className={classes.condition}>{initItem.condition}</div>
                <div className={classnames(classes.qty, classes.inbound)}>
                  {increaseState ? sum + initItem?.quantity : initItem?.quantity - sum} <span>Pcs</span>
                </div>
              </div>

              <TitleWithDivider title="Order(s)" />
              {compensatoryItems?.map(item => (
                <div key={item.depot} className={classnames(classes.orderContainer, classes.greenBorder)}>
                  <div className={classes.depotName}>{item.depot}</div>
                  <div className={classes.assetType}>{initItem.group}</div>
                  <div className={classes.condition}>{initItem.condition}</div>
                  <div className={classnames(classes.qty, classes.inbound)}>
                    {item.quantity} <span>Pcs</span>
                  </div>
                </div>
              ))}
            </div>
          );
        }
        if (selectedType === STO_TYPES.INBOUND && isNew) {
          const grouped = groupBy(inTransitState?.compensatoryItems, "depot");
          const updatedList = Object.keys(grouped).map(item => {
            const itemSum = grouped[item].reduce((a, i) => a + i.quantity, 0);
            return {
              ...grouped[item][0],
              quantity: itemSum,
            };
          });

          const compensatoryItems = updatedList.map(item => {
            const foundStop = trip?.stops.find(
              i => i.status !== "Passed" && i.location.depot === item.depot && i.orderedInAssets.length
            );
            const foundAssetCount =
              foundStop?.orderedInAssets?.find(
                asset => asset?.condition === assets[0]?.condition && asset?.group === assets[0]?.group
              )?.quantity || 0;

            return foundAssetCount > 0
              ? {
                  ...item,
                  quantity: foundAssetCount - item.quantity,
                }
              : item;
          });

          const firstItem = inTransitState?.compensatoryItems[0];

          return (
            <div>
              <TitleWithDivider title="Inbound Order" />
              <div className={classnames(classes.orderContainer, classes.redBorder)}>
                <div className={classes.depotName}>{firstItem?.depot}</div>
                <div className={classes.assetType}>{firstItem?.group}</div>
                <div className={classes.condition}>{firstItem?.condition}</div>
                <div className={classnames(classes.qty, classes.inbound)}>
                  {firstItem?.quantity} <span>Pcs</span>
                </div>
              </div>

              <TitleWithDivider title="Order(s)" />
              {compensatoryItems?.map(
                (item, i) =>
                  i > 0 && (
                    <div key={item.depot} className={classnames(classes.orderContainer, classes.greenBorder)}>
                      <div className={classes.depotName}>{item.depot}</div>
                      <div className={classes.assetType}>{firstItem.group}</div>
                      <div className={classes.condition}>{firstItem.condition}</div>
                      <div className={classnames(classes.qty, classes.outbound)}>
                        {item.quantity} <span>Pcs</span>
                      </div>
                    </div>
                  )
              )}
            </div>
          );
        }
        break;
      default:
        return "Summary";
    }
  }, [trip, selectedType, inTransitState, initItem, editedItem, STOData, increaseState]);

  const submitFn = useMemo(() => {
    switch (trip?.status) {
      case TRIP_STATUSES.IN_TRANSIT:
        if (!isNew) {
          return onUpdateSubmit;
        }
        if (isNew && selectedType === STO_TYPES.OUTBOUND) {
          return openConfirmationModal;
        }
        if (isNew && selectedType === STO_TYPES.INBOUND) {
          return onSaveNewInbound;
        }
        return () => {};
      default:
        return openConfirmationModal;
    }
  }, [trip, selectedType, onUpdateSubmit, openConfirmationModal, assets]);

  return (
    <Modal
      title={
        <div className={classes.infoContainer}>
          {!showSummary ? (
            <>
              {isNew ? "Create" : "Edit"} Stock Transfer Order Item
              <span>Please fill in all mandatory fields</span>
            </>
          ) : (
            <>
              Confirm Asset Distribution
              <span>Please review and confirm the following asset distribution</span>
            </>
          )}
        </div>
      }
      className={classes.modal}
      open
      onCancel={openConfirmCloseModal}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NotFoundContainer
          code=""
          text={`Action is not valid.
            If it is possible back to the previous step and try another option.`}
          isModal
          isNotFound={isNotFound}
        >
          {!showSummary ? (
            <NGIForm name="newSTO" form={form} onFinish={onSave}>
              <TitleWithDivider title="General Information" />
              <Row className={classes.formItem}>
                <Col span={12}>
                  <Title level={5}>Order Type</Title>
                  <NGIFormItem name="type" rules={VALIDATION_RULES}>
                    <NGISelect
                      disabled={!isNew || disableMap.type}
                      value={selectedType}
                      onChange={handleTypeChange}
                      placeholder="Select value"
                    >
                      {ORDER_TYPES.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </NGISelect>
                  </NGIFormItem>
                </Col>
                <Col span={12}>
                  <Title level={5}>Preferred Date</Title>
                  <NGIFormItem name="preferredDate">
                    <NGIDatePicker disabled />
                  </NGIFormItem>
                </Col>
              </Row>

              {userHasOneOfRoles([
                USER_ROLES.SYSTEM_FULL_ADMINS,
                USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                USER_ROLES.IT_OPERATIONS,
                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                USER_ROLES.WAREHOUSE,
              ]) && (
                <Row className={classes.formItem}>
                  <Col span={12}>
                    <Title level={5}>To Depot</Title>
                    <NGIFormItem
                      name="toDepot"
                      rules={[
                        {
                          required: selectedType === STO_TYPES.INBOUND || isNew,
                          message: "Please enter value",
                        },
                      ]}
                    >
                      <NGISelect
                        showSearch
                        disabled={disableMap.toDepot || !selectedType}
                        onChange={onToChanged}
                        placeholder={"Select value"}
                      >
                        {(
                          depotListTo ||
                          (depot[USER_TENANT_NAME] &&
                            uniq(depot[USER_TENANT_NAME])
                              ?.filter(item => {
                                if (!isNew) {
                                  return item !== selectedFromDepot && item !== stop?.location?.depot;
                                }
                                return item !== selectedFromDepot;
                              })
                              ?.sort())
                        )?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </NGISelect>
                    </NGIFormItem>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>From Depot</Title>
                    <NGIFormItem
                      name="fromDepot"
                      rules={[
                        {
                          required: selectedType === STO_TYPES.OUTBOUND || isNew,
                          message: "Please enter value",
                        },
                      ]}
                    >
                      <NGISelect
                        showSearch
                        onChange={onFromChanged}
                        disabled={disableMap.fromDepot || !selectedType}
                        placeholder={"Select value"}
                      >
                        {(
                          depotListFrom ||
                          (depot[USER_TENANT_NAME] &&
                            uniq(depot[USER_TENANT_NAME])
                              ?.filter(item => item !== selectedToDepot)
                              ?.sort())
                        )?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </NGISelect>
                    </NGIFormItem>
                  </Col>
                </Row>
              )}

              <TitleWithDivider
                title="List Of Assets"
                button={inTransitState && `Remaining QTY: ${inTransitState?._quantityLeft}`}
              />
              <div className={classes.assetsContainer}>
                {assets.map((item, i) => (
                  <EditAssetRow
                    type={selectedType}
                    key={item.id}
                    index={i}
                    maxValue={!isNaN(parseInt(maxValue)) ? maxValue : inTransitState?._quantityLeft}
                    isNew={isNew}
                    setAssets={setAssets}
                    onRemoveAsset={onRemoveAsset}
                    item={item}
                    disableMap={disableMap}
                    isDisabled={editId !== item.id || createType === UNASSIGN_CREATE_TYPE.RECREATE}
                  />
                ))}

                {isNew && assets.length === 0 && (
                  <div
                    className={classnames(classes.addNewAsset, !selectedType && classes.disabled)}
                    onClick={onAddAsset}
                  >
                    + Add New Asset
                  </div>
                )}
              </div>
            </NGIForm>
          ) : (
            <div>{renderSummary()}</div>
          )}
        </NotFoundContainer>
      </Spin>
      <Divider />
      <br />
      <Space>
        <CustomButton
          onClick={openConfirmCloseModal}
          type="primary"
          size="small"
          color="outlined"
          text={showSummary ? "Discard" : "Close"}
        />
        {showSummary ? (
          <CustomButton
            type="primary"
            disabled={!assets.length}
            onClick={openConfirmationModal}
            text="Confirm"
            size="small"
          />
        ) : (
          <CustomButton
            type="primary"
            disabled={!assets.length || isNotFound}
            onClick={submitFn}
            text={isNew ? "Submit" : "Save"}
            size="small"
          />
        )}
      </Space>

      {isConfirmationOpen && (
        <ConfirmationModal
          description={`Are you sure you want to ${isNew ? "add the new" : "edit"} STO?`}
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          loading={isLoading}
        />
      )}
      {isConfirmCloseOpen && (
        <ConfirmationModal
          description={
            isNew
              ? "STO adding is in progress. The new STO will not be added after the closing of the popup. Are you sure you want to close it?"
              : "STO editing is in progress. Are you sure you want to close it?"
          }
          onCancel={closeConfirmCloseModal}
          onConfirm={onClose}
        />
      )}
      {allowTypeChangeConfirmation && (
        <ConfirmationModal
          description="The order type is changed. All added assets will be removed. Are you sure you want to change the type?"
          onCancel={closeConfirmTypeChangeModal}
          onConfirm={onTypeChanged}
        />
      )}
      {!!showDecreaseConfirm && (
        <ConfirmationModal
          title="Asset Deduction"
          cancelBtnTitle="Cancel"
          confirmBtnTitle="Continue"
          description={`Please select an STO you want to deduct ${showDecreaseConfirm} ${editedItem.condition} ${editedItem.group}(s) in order to complete the STO change.`}
          onCancel={closeConfirmDecreaseModal}
          onConfirm={onDecreaseInTransit}
        />
      )}
      {!!showDecreaseNewInboundConfirm && (
        <ConfirmationModal
          title="Asset Deduction"
          cancelBtnTitle="Cancel"
          confirmBtnTitle="Continue"
          description={`Please select an STO you want to deduct ${showDecreaseNewInboundConfirm} ${assets[0]?.condition} ${assets[0]?.group}(s) in order to complete the STO change.`}
          onCancel={closeConfirmDecreaseNewInboundModal}
          onConfirm={onDecreaseNewInbound}
        />
      )}
      {isUpdateOpen && (
        <ConfirmationUpdateModal
          title="Confirm Updating STO"
          onCancel={onUpdateClose}
          onConfirm={onUpdateInTransitConfirm}
          updateReasons={UPDATE_REASONS[TRIP_STATUSES.IN_TRANSIT][selectedType]}
        />
      )}
    </Modal>
  );
};

export default EditSTOModal;
