import { Container } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsDisabledField, VALIDATION_RULES } from "./constants";
import Header from "./Header";
import { Card, CardBody } from "reactstrap";
import NGIForm from "components/Common/form/NGIForm";
import { Col, Divider, Form, Row, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import { makeStyles } from "@material-ui/core/styles";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import { uniq } from "lodash";
import { DictionariesSelector } from "store/dictionaries/selectors";
import NGIInput from "components/Common/inputs/NGIInput";
import CustomButton from "components/Common/buttons/CustomButton";
import moment from "moment";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import { updateTrip, retrieveTripDetails } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { useHistory, useParams } from "react-router";
import { USER_TENANT_NAME } from "utils/constants";
import { getTrimmedObjectValues } from "utils/helpers/functions";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { TRIP_STATUSES } from "pages/stock-management/trips/constants";
import StopItem from "pages/stock-management/trips/editTrip/editSTO/StopItem";
import EditSTOModal from "pages/stock-management/trips/editTrip/editSTO/EditSTOModal";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: "10px 30px",
  },
  cardBody: {
    padding: 0,
  },
  formItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  addNewStop: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
});

const EditTrip = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [form] = Form.useForm();
  const [open, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trip, setTrip] = useState({});
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [changedList, setChangedList] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const {
    list: { depot, truckLicensePlates },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.TRUCK_LICENSE_PLATES));
  }, []);

  const onRetrieveTripDetails = useCallback(() => {
    setIsLoading(true);
    retrieveTripDetails(id)
      .then(({ data }) => {
        const { startLocation, startDate, startTime, vehicle } = data.result;
        form.setFieldsValue({
          startLocation,
          preferredDate: moment(`${startDate} ${startTime}`),
          driverName: vehicle?.driverName,
          driverLicenseID: vehicle?.driverLicenseID,
          truckLicensePlate: vehicle?.truckLicensePlate,
          trailerLicensePlate: vehicle?.trailerLicensePlate,
        });
        setTrip(data.result);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [form]);

  useEffect(() => {
    onRetrieveTripDetails();
  }, []);

  const onSave = useCallback(() => {
    onCloseConfirmation();
    const values = form.getFieldsValue();
    const data = {
      ...getTrimmedObjectValues(values),
      preferredTime: moment(values.preferredDate).format("HH:mm"),
      preferredDate: customDateFormatWithoutTime(values.preferredDate),
    };
    setIsLoading(true);
    updateTrip(id, data)
      .then(() => {
        successMessage("Trip was updated successfully");
        setIsLoading(false);
        handleCancel();
      })
      .catch(e => {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      });
  }, [form]);

  const handleCancel = useCallback(() => {
    history.push("/stock-management/trips");
  }, []);

  const onAddSto = useCallback(() => setIsAddOpen(true), []);
  const onNewSTOClose = useCallback(() => setIsAddOpen(false), []);

  const openConfirmation = useCallback(() => setIsOpen(true), []);
  const onCloseConfirmation = useCallback(() => setIsOpen(false), []);

  const lastStopType = useMemo(() => {
    // if (
    //   trip?.stops?.filter(item => item.status === "Assigned" || item.status === "Empty")?.length === 1 &&
    //   trip?.status === TRIP_STATUSES.IN_TRANSIT &&
    //   trip?.stops?.length > 2
    // ) {
    //   return "last";
    // }
    // if (trip?.status === TRIP_STATUSES.IN_TRANSIT && trip?.stops?.length === 2) {
    //   return "hasOnly2";
    // }
    // if (trip?.status === TRIP_STATUSES.IN_TRANSIT && trip?.stops?.length > 0) {
    //   return "inTransit";
    // }
    // if (trip?.status === TRIP_STATUSES.IN_TRANSIT && trip?.stops?.length === 0) {
    //   return "inTransitConfirm";
    // }
    if (trip?.status === TRIP_STATUSES.PENDING || trip?.status === TRIP_STATUSES.PLANNED) {
      return "pendingPlaned";
    }
  }, [trip]);

  const additionalData = useMemo(() => {
    switch (trip?.status) {
      case TRIP_STATUSES.IN_TRANSIT:
        const skipDepotsTo = trip?.stops
          ?.filter((item, k) => (item.status === "Passed" || item.status === "InProgress") && k !== 0)
          .map(item => item.location.depot);
        const skipDepotsFrom = trip?.stops
          ?.filter(item => item.status === "Passed" || item.status === "InProgress")
          .map(item => item.location.depot);
        return {
          availableDepotTo: depot[USER_TENANT_NAME].filter(item => !skipDepotsTo.includes(item)),
          availableDepotFrom: depot[USER_TENANT_NAME].filter(item => !skipDepotsFrom.includes(item)),
        };
      default:
        return {};
    }
  }, [trip]);

  const onFieldChange = useCallback(() => isSaveDisabled && setIsSaveDisabled(false), [isSaveDisabled]);

  return (
    <div className="page-content">
      <Container fluid maxWidth={"xl"}>
        <Header id={id} trip={trip} />
        <div className={classes.root}>
          <Card className={classes.container}>
            <CardBody className={classes.cardBody}>
              <Spin spinning={isLoading}>
                <NGIForm name="newTrip" form={form} onFinish={onSave} onValuesChange={onFieldChange}>
                  <TitleWithDivider title="General Information" />
                  <Row className={classes.formItem}>
                    <Col span={7}>
                      <Title level={5}>Start Location</Title>
                      <NGIFormItem name="startLocation" rules={VALIDATION_RULES}>
                        <NGISelect
                          disabled={getIsDisabledField(trip.status, "startLocation")}
                          showSearch
                          placeholder={"Select value"}
                        >
                          {depot &&
                            depot[USER_TENANT_NAME] &&
                            uniq(depot[USER_TENANT_NAME])
                              ?.sort()
                              ?.map(item => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                        </NGISelect>
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Trip Date</Title>
                      <NGIFormItem name="preferredDate" rules={VALIDATION_RULES}>
                        <NGIDatePicker
                          disabled={getIsDisabledField(trip.status, "preferredDate")}
                          showTime={{ format: "HH:mm" }}
                          disabledPasDates
                          allowClear
                          dateFormat={"YYYY-MM-DD HH:mm"}
                        />
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Driver Name</Title>
                      <NGIFormItem name="driverName" rules={VALIDATION_RULES}>
                        <NGIInput
                          disabled={getIsDisabledField(trip.status, "driverName")}
                          placeholder={"Please enter value"}
                        />
                      </NGIFormItem>
                    </Col>
                  </Row>

                  <Row className={classes.formItem}>
                    <Col span={7}>
                      <Title level={5}>Driver License ID</Title>
                      <NGIFormItem
                        name="driverLicenseID"
                        rules={[
                          ...VALIDATION_RULES,
                          {
                            max: 50,
                            message: "The max length is 50",
                          },
                        ]}
                      >
                        <NGIInput
                          disabled={getIsDisabledField(trip.status, "driverLicenseID")}
                          placeholder={"Please add value"}
                        />
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Truck License Plate</Title>
                      <NGIFormItem name="truckLicensePlate" rules={VALIDATION_RULES}>
                        <NGISelect
                          disabled={getIsDisabledField(trip.status, "truckLicensePlate")}
                          showSearch
                          placeholder={"Select value"}
                        >
                          {truckLicensePlates &&
                            truckLicensePlates[USER_TENANT_NAME] &&
                            truckLicensePlates[USER_TENANT_NAME]?.map(item => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                        </NGISelect>
                      </NGIFormItem>
                    </Col>
                    <Col span={7}>
                      <Title level={5}>Trailer License Plate</Title>
                      <NGIFormItem
                        name="trailerLicensePlate"
                        rules={[
                          ...VALIDATION_RULES,
                          {
                            max: 50,
                            message: "The max length is 50",
                          },
                        ]}
                      >
                        <NGIInput
                          disabled={getIsDisabledField(trip.status, "trailerLicensePlate")}
                          placeholder={"Please add value"}
                        />
                      </NGIFormItem>
                    </Col>
                  </Row>
                  <Divider />
                </NGIForm>
                <div className={classes.buttons}>
                  <Space>
                    <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
                    <CustomButton
                      disabled={isSaveDisabled}
                      onClick={openConfirmation}
                      type="primary"
                      size="small"
                      text="Save changes"
                    />
                  </Space>
                </div>
              </Spin>
            </CardBody>
          </Card>

          {open && (
            <ConfirmationModal
              onCancel={onCloseConfirmation}
              onConfirm={onSave}
              description="Are you sure you want to change the trip details?"
            />
          )}

          <Card className={classes.container}>
            <TitleWithDivider
              title="List Of Stops"
              button={
                <CustomButton
                  disabled={getIsDisabledField(trip.status, "addNewSTO")}
                  onClick={onAddSto}
                  type="primary"
                  size="small"
                  text="Add new sto"
                />
              }
            />
            <CardBody className={classes.cardBody}>
              {trip?.stops?.map(stop => (
                <StopItem
                  changedList={changedList}
                  setChangedList={setChangedList}
                  lastStopType={lastStopType}
                  key={stop.id}
                  stop={stop}
                  showActions={!getIsDisabledField(trip?.status, "removeEditSTO")}
                  update={onRetrieveTripDetails}
                  trip={trip}
                  disableStatusList={["Passed", "InProgress"]}
                />
              ))}
            </CardBody>
          </Card>
        </div>
        {isAddOpen && (
          <EditSTOModal
            trip={trip}
            additionalData={additionalData}
            onClose={onNewSTOClose}
            setChangedList={setChangedList}
            update={onRetrieveTripDetails}
          />
        )}
      </Container>
    </div>
  );
};

export default EditTrip;
