import React, { useCallback } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { SEARCH_TYPES_OPTIONS } from "pages/meter-management/ready-for-repair/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";

const { Option } = Select;

const ReadyForRepairSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.READY_FOR_REPAIR);
  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    !(values.value && !values.type) &&
      handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : { pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch placeholder="Select type" allowClear>
            {Object.keys(SEARCH_TYPES_OPTIONS).map(key => (
              <Option key={key} value={key}>
                {SEARCH_TYPES_OPTIONS[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        <Form.Item name="value">
          <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
        </Form.Item>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default ReadyForRepairSearch;
