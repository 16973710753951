import { customDateFormatWithTime, statusFormatterWithMap, textFormatter } from "components/Common/formatter";
import ButtonGroupContainer from "../../../components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "../../../components/Common/buttons/ButtonGroup";
import { USER_ROLES } from "../../../utils/constants";
import deleteIcon from "../../../assets/images/svg/Delete.svg";
import React from "react";
import { STATUS_COLOR_MAP } from "./constants";

export const palletListColumns = onDelete => [
  {
    title: "Pallet ID",
    dataIndex: "id",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: value => statusFormatterWithMap(value, STATUS_COLOR_MAP),
  },
  {
    title: "Tenant",
    dataIndex: "tenant",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Asset Type",
    dataIndex: "assetGroup",
    render: textFormatter,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Qty",
    dataIndex: "assetQuantity",
    render: textFormatter,
  },
  {
    title: "",
    key: "action",
    render: (text, record) => (
      <ButtonGroupContainer>
        <ButtonGroup
          roles={[USER_ROLES.PRODUCT_TECHNICIANS, USER_ROLES.PRODUCT_TECHNICIAN_LEAD, USER_ROLES.WAREHOUSE]}
          tooltipTitle="Delete"
          icon={deleteIcon}
          handleClick={() => onDelete(record.id)}
        />
      </ButtonGroupContainer>
    ),
  },
];
