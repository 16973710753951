import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import successIcon from "assets/images/svg/lb-success.svg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 40px",
    alignItems: "center",
    justifyContent: "center",
  },
  action: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 15,
  },
  desc: {
    fontSize: 13,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: 15,
  },
  img: {
    width: 160,
    marginBottom: 15,
  },
});

const SuccessScreen = ({ onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.img} src={successIcon} alt="success" />
      <div className={classes.title}>Shipment Created Successfully!</div>
      <div className={classes.action}>
        <CustomButton onClick={onClose} type="primary" size="small" text="Ok" />
      </div>
    </div>
  );
};

export default SuccessScreen;
