import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { createDictionary } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIPrompt from "components/Common/NGIPrompt";
import CustomButton from "../../../components/Common/buttons/CustomButton";
import editIcon from "assets/images/svg/Pencil Drawing.svg";
import NewMeterInspectionTaskReasonsModal from "./NewMeterInspectionTaskReasonsModal";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  builderContainer: {
    width: "50%",

    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  reasonItem: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    padding: 20,
    marginTop: 30,
    marginBottom: 15,
  },
  subItemContainer: {
    paddingLeft: 40,
    marginLeft: 15,
    position: "relative",

    "&:before": {
      content: "''",
      width: 1,
      height: "100%",
      position: "absolute",
      top: 0,
      left: 6,
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
    "&:last-child:before": {
      height: 46,
    },

    "&:after": {
      content: "''",
      width: 20,
      height: 1,
      position: "absolute",
      top: 45,
      left: 7,
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
  },
  reasonSubItem: {
    paddingTop: 15,
  },
  subItemTitle: {
    display: "flex",
    justifyContent: "space-between",
    background: "#f0f8ff",
    fontStyle: "italic",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 5,
    padding: 20,
  },
  imgBtn: {
    cursor: "pointer",
    marginLeft: 10,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  noReasons: {
    marginTop: 20,
  },
  requiredFields: {
    opacity: 0.5,
    fontSize: 12,
  },
});

const MeterInspectionTaskReasonsReasons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentReason, setCurrentReason] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [allReasons, setAllReasons] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const {
    list: { meterInspectionTaskReasons },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.METER_INSPECTION_TASK_REASONS));
    dispatch(getDictionary(DICTIONARY_TYPES.TASK_CATEGORY));
  }, []);

  useEffect(() => {
    if (meterInspectionTaskReasons?.length) {
      setReasons(meterInspectionTaskReasons);
    }
  }, [meterInspectionTaskReasons]);

  const editReason = useCallback(
    event => {
      setCurrentReason({
        reason: event.currentTarget.dataset.reason,
        type: event.currentTarget.dataset.type,
      });
      setAllReasons(reasons);
      setIsOpen(true);
    },
    [reasons]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setAllReasons([]);
    setCurrentReason(null);
  }, []);

  const handleAddNew = useCallback(() => {
    setAllReasons(reasons);
    setIsOpen(true);
  }, [reasons]);

  const handleSaveReasons = useCallback(async () => {
    const formattedData = {
      name: DICTIONARY_TYPES.METER_INSPECTION_TASK_REASONS,
      items: reasons,
    };

    setLoader(true);
    await createDictionary(formattedData)
      .then(() => {
        setLoader(false);
        successMessage("Reasons saved successfully");
        setDisabled(true);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
        setDisabled(false);
      });
  }, [reasons]);

  const handleSave = useCallback(
    (reason, taskCategory) => {
      setDisabled(false);

      setReasons(prevState => {
        const state = [...prevState];

        if (currentReason) {
          const foundIndex = state.findIndex(item => item.reason === currentReason.reason);
          state[foundIndex] = { reason, taskCategory };
        } else {
          state.push({ reason, taskCategory });
        }
        return state;
      });
      handleClose();
    },
    [currentReason]
  );

  return (
    <>
      <NGIPrompt show={!disabled} />
      <div className="page-content">
        <Container fluid>
          <Spin spinning={isLoading}>
            <>
              <div className={classes.root}>
                <div className={classes.cardBody}>
                  <div className={classes.buttonsContainer}>
                    <CustomButton
                      icon={<i className="bx bx-plus-circle" />}
                      type="primary"
                      text="NEW REASON"
                      onClick={handleAddNew}
                    />
                    <CustomButton type="primary" text="Save" disabled={disabled} onClick={handleSaveReasons} />
                  </div>
                  <div className={classes.builderContainer}>
                    {reasons.map(item => (
                      <div key={item}>
                        <div className={classes.reasonItem}>
                          <div>{item.reason}</div>
                          <div>
                            <img
                              data-reason={item.reason}
                              data-type={item.taskCategory}
                              className={classes.imgBtn}
                              onClick={editReason}
                              src={editIcon}
                              alt="action-icon"
                            />
                          </div>
                        </div>
                        {item.taskCategory && (
                          <div className={classes.subItemContainer}>
                            <div className={classes.reasonSubItem}>
                              <div className={classes.subItemTitle}>
                                <div>{item.taskCategory}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {!reasons.length && (
                    <Card className={classes.noReasons}>
                      <CardBody className="text-center">
                        <i className="bx bx-warning" />
                        <h5>No reasons</h5>
                        <p className="text-muted">Please add new reason</p>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </div>
            </>
          </Spin>
        </Container>
        {isOpen && (
          <NewMeterInspectionTaskReasonsModal
            onClose={handleClose}
            reason={currentReason?.reason}
            type={currentReason?.type}
            onSave={handleSave}
            allReasons={allReasons}
          />
        )}
      </div>
    </>
  );
};

export default MeterInspectionTaskReasonsReasons;
