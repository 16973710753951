import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row, Spin } from "antd";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { debounce } from "lodash";
import { fetchMTShippingContainers, retrieveAllAssets } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import removeIcon from "assets/images/svg/Close.svg";
import { ALLOWED_STATUSES, IMG_MAP, SHIPMENT_TYPES } from "../../constants";

const useStyles = makeStyles({
  root: {
    paddingTop: 20,
  },
  select: {
    width: "100%",

    "& .ant-select-selector": {
      border: "none!important",
    },
  },
  searchRow: {
    border: "1px solid #00000026",
    borderRadius: 4,
    "&>:first-child": {
      borderRight: "1px solid #00000026",
    },
  },
  groupTitle: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 16,
    fontWeight: 600,
    padding: "10px 0",
  },
  addedListContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxHeight: 600,
    overflow: "auto",
    padding: "5px 2px",
  },
  itemWrapper: {
    width: "100%",
    backgroundColor: "#00000008",
    borderRadius: 5,
    padding: 12,
    marginTop: 6,
    position: "relative",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  removeIcon: {
    cursor: "pointer",
    height: 20,
  },
  typeName: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    "&>img": {
      marginRight: 5,
      width: 20,
    },
  },
  qty: {
    width: "20%",
  },
  error: {
    fontSize: 9,
    color: "#FF0000",
    position: "absolute",
    bottom: 3,
    left: 37,
  },
});

const AssetsList = ({ data, addedList, setAddedList, statusList = {} }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [shipmentType, setShipmentType] = useState(SHIPMENT_TYPES[0]);

  const search = useCallback(
    value => {
      if (value) {
        setList([]);
        setIsLoading(true);
        const request =
          shipmentType === "Individually"
            ? retrieveAllAssets({
                qrCode: value,
              })
            : fetchMTShippingContainers(shipmentType, { [`${shipmentType.toLowerCase()}Id`]: value });

        request
          .then(res => {
            const {
              data: { result },
            } = res;
            const newList =
              shipmentType === "Individually"
                ? result?.items?.filter(item => ALLOWED_STATUSES[item.typeGroup].includes(item.state))
                : result?.items;
            setList(newList || null);
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
            defaultNgiErrorMethod(err);
          });
      }
    },
    [shipmentType]
  );

  const onSelect = useCallback(
    value => {
      const foundItem = addedList.find(item => item.id === value);
      if (!foundItem) {
        const foundAsset = list.find(item => item.id === value);
        setAddedList(prevState => [
          ...prevState,
          shipmentType === "Individually"
            ? {
                qrCode: foundAsset.qrCode,
                qty: 1,
                type: foundAsset.typeGroup,
              }
            : {
                id: value,
                qty: foundAsset.assetQuantity,
                type: shipmentType,
              },
        ]);
        setList([]);
      } else {
        warnMessage("Already added!");
        setList([]);
      }
    },
    [data, addedList, list, shipmentType]
  );

  const onRemove = useCallback(e => {
    const id = e.currentTarget.dataset.id;
    setAddedList(prevState => prevState.filter(item => (item?.qrCode || item?.id) !== id));
  }, []);

  const debouncedSearch = useMemo(() => debounce(search, 1000), [search]);

  const qtySum = useMemo(() => addedList.reduce((count, obj) => count + obj.qty, 0), [addedList]);

  return (
    <div className={classes.root}>
      <Row className={classes.searchRow}>
        <Col span={6}>
          <NGISelect
            className={classes.select}
            placeholder="Select shipment type"
            onSelect={setShipmentType}
            value={shipmentType}
          >
            {SHIPMENT_TYPES.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </NGISelect>
        </Col>
        <Col span={18}>
          <NGISelect
            allowClear
            showSearch
            className={classes.select}
            loading={isLoading}
            placeholder={`Search and select ${shipmentType}`}
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
            onSearch={debouncedSearch}
            onSelect={onSelect}
            value={null}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
          >
            {list.map(item => (
              <Option key={item.id} value={item.id}>
                {item?.qrCode || item?.id}
              </Option>
            ))}
          </NGISelect>
        </Col>
      </Row>
      <div className={classes.groupTitle}>
        <span>{qtySum} Pcs</span>
      </div>
      <div className={classes.addedListContainer}>
        {addedList.map(item => (
          <div className={classes.itemWrapper} key={item.id}>
            <div className={classes.listItem}>
              <div className={classes.typeName}>
                <img src={IMG_MAP[item.type]} alt="asset-type" />
                <span>{item?.qrCode || item?.id}</span>
              </div>
              <span className={classes.qty}>x{item.qty}</span>
              <img
                onClick={onRemove}
                className={classes.removeIcon}
                data-id={item?.qrCode || item?.id}
                src={removeIcon}
                alt="remove"
              />
            </div>
            {statusList[item?.qrCode || item?.id]?.error && (
              <div className={classes.error}>{statusList[item?.qrCode || item?.id]?.error}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetsList;
