export const searchOptions = [
  {
    value: "status",
    title: "Status",
  },
  {
    value: "assetGroup",
    title: "Asset Type",
  },
  {
    value: "date",
    title: "Created At",
  },
  {
    value: "palletId",
    title: "Pallet ID",
  },
  {
    value: "shipmentId",
    title: "Shipment Order",
  },
  {
    value: "depot",
    title: "Depot",
  },
];

export const STATUS = ["Available", "Prepared", "InTransit", "Received"];

export const STATUS_COLOR_MAP = {
  Available: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  Prepared: {
    color: "#db6605",
    backgroundColor: "#ffe3cc",
  },
  InTransit: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  Received: {
    color: "#19c346",
    backgroundColor: "#d7ffe3",
  },
};
